@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom scrollbar styles */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #18181b; /* Equivalent to theme('colors.zinc.900') */
}

::-webkit-scrollbar-thumb {
  background: #3f3f46; /* Equivalent to theme('colors.zinc.700') */
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #52525b; /* Equivalent to theme('colors.zinc.600') */
}

/* Base styles */
body {
  @apply bg-zinc-900 text-white antialiased;
}

/* Custom focus styles */
*:focus-visible {
  @apply outline-none ring-2 ring-blue-400 ring-opacity-50;
}

/* Custom styles for the chat input */
textarea {
  @apply bg-transparent resize-none;
}

textarea::placeholder {
  @apply text-zinc-400;
}

/* Transitions */
.transition-all {
  transition: all 200ms ease-in-out; /* Define transition properties directly */
}

/* Custom animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animate-fade-in {
  animation: fadeIn 0.3s ease-in-out;
}

.prose ul,
.prose ol {
  margin-left: 1rem; /* Adjust to set list indent */
  padding-left: 1rem; /* Add padding to control spacing */
}

.prose li {
  margin-bottom: 0.5rem; /* Add some space between list items */
}

.animate-slide-right {
  animation: slideRight 0.3s ease-in-out;
}

.animate-slide-left {
  animation: slideLeft 0.3s ease-in-out;
}

@keyframes slideRight {
  0% { transform: translateX(100%); opacity: 0; }
  100% { transform: translateX(0); opacity: 1; }
}

@keyframes slideLeft {
  0% { transform: translateX(-100%); opacity: 0; }
  100% { transform: translateX(0); opacity: 1; }
}

.message-box {
  background-color: #18181b;
  border-radius: 1rem;
  padding: .8rem 1rem .5rem 1rem;
  margin-bottom: 1.5rem;
  color: #e0e0e0;
  border: 1px solid #333;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
}

.message-box:hover {
  background-color: #2d2d2d;
}

.message-box .timestamp {
  font-size: 0.75rem; /* Smaller timestamp */
  color: #a1a1a1; /* Subtle color for timestamp */
}

.message-box .content {
  font-size: 0.875rem; /* Reduce font size slightly */
  color: #e0e0e0; /* Lighter message text */
}

@keyframes wiggle {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(-10deg); }
  50% { transform: rotate(10deg); }
  75% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
}

.animate-wiggle {
  animation: wiggle 1s ease-in-out;
}

/* Fade animations */
.fade-enter {
  opacity: 0;
  transform: translateY(10px);
}

.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms ease-out, transform 300ms ease-out;
}

.fade-exit {
  opacity: 1;
  transform: translateY(0);
}

.fade-exit-active {
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 300ms ease-in, transform 300ms ease-in;
}

/* Slide animations for Relic Panel */
.slide-enter {
  transform: translateX(100%);
}

.slide-enter-active {
  transform: translateX(0);
  transition: transform 300ms ease-out;
}

.slide-exit {
  transform: translateX(0);
}

.slide-exit-active {
  transform: translateX(100%);
  transition: transform 300ms ease-in;
}

/* Custom animations */
@keyframes cursor-blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

.cursor-animation {
  animation: cursor-blink 1s infinite;
}

/* Loading animations */
@keyframes loading-gradient {
  0% { background-position: 200% 0; }
  100% { background-position: -200% 0; }
}

.loading-gradient {
  background: linear-gradient(
    90deg,
    theme('colors.zinc.800') 25%,
    theme('colors.zinc.700') 50%,
    theme('colors.zinc.800') 75%
  );
  background-size: 200% 100%;
  animation: loading-gradient 2s infinite;
}

@media (max-width: 1536px) {
  .scroll-to-bottom-button {
    right: 2rem;
  }
}

/* Relic preview hover effects */
.relic-preview-hover {
  @apply transition-all duration-200;
}

.relic-preview-hover:hover {
  @apply transform scale-[1.01];
  box-shadow: 0 0 0 1px theme('colors.zinc.700'),
              0 4px 6px -1px rgba(0, 0, 0, 0.1),
              0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

/* Smooth transitions for chat container */
.chat-container-transition {
  transition: margin-right 300ms ease-in-out, max-width 300ms ease-in-out;
}

/* Ensure the container takes up available space */
.chat-container {
  width: 100%;
}

/* Shared focus styles */
.focus-ring {
  @apply focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50;
}

.relic-backdrop.visible {
  opacity: 1;
}

.drag-handle {
  cursor: move;
}

.drag-handle * {
  cursor: default;
}

.drag-handle button {
  cursor: pointer;
}

@keyframes stream {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.resize-handle {
  @apply absolute bg-transparent;
  &:hover {
    @apply bg-blue-500/20;
  }
}

.resize-overlay {
  animation: resize-feedback 0.2s ease-in-out;
}

@keyframes resize-feedback {
  0% { opacity: 0; }
  100% { opacity: 0.1; }
}

/* Tilt and wiggle animations */
@keyframes wiggle {
  0%, 100% { transform: rotate(0deg); }
  25% { transform: rotate(-15deg); }
  75% { transform: rotate(15deg); }
}

@keyframes tiltRight {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(10deg); }
}

@keyframes resetTilt {
  0% { transform: rotate(10deg); }
  100% { transform: rotate(0deg); }
}

.wiggle-hover {
  animation: wiggle 1.5s ease-in-out;
}

.tilt-right {
  animation: tiltRight 0.2s forwards;
}

.reset-tilt {
  animation: resetTilt 0.2s forwards;
}

@keyframes floatUp {
  0% {
    transform: translateY(20px);
    opacity: 0;
    scale: 0.9;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
    scale: 1;
  }
}

.message-float {
  animation: floatUp 0.5s ease-out;
}
